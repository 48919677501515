<div class="container">
  <div id="logo-container" class="row d-flex flex-wrap align-items-center justify-content-center">
    <div id="nissan-logo" class="col-12 text-center">
      <img class="img-fluid" src="assets/logo.png" alt="Ken Pollock Nissan">
      <br>
      <a class="btn btn-outline-light btn-sm m-3" href="https://kenpollocknissan.com/?utm_source=hazletonissan.com">
        Visit Ken Pollock Nissan
      </a>
    </div>
  </div>
</div>
<footer>
  <div class="mt-4">
    <a class="btn btn-outline-light m-3" href="tel:15708190730">
      <fa-icon [icon]="faPhone"></fa-icon> 1-570-819-0730
    </a>
    <a class="btn btn-outline-light m-3" href="https://g.page/KenPollockNissan">
      <fa-icon [icon]="faAddress"></fa-icon> 229 Mundy St. Wilkes-Barre, PA 18702
    </a>
  </div>
  <a class="btn btn-outline-light m-3" href="https://kenpollockautogroup.com?utm_source=hazletonissan.com">
    &copy; {{ currentYear }} Ken Pollock Auto Group
  </a>
</footer>
