import { Component } from '@angular/core';
import {
  faPhone,
  faLocationArrow,
  faWrench
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  currentYear = (new Date()).getFullYear();
  faPhone = faPhone;
  faAddress = faLocationArrow;
  faService = faWrench;
}
